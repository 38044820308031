html, body, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  min-width: 320px;
}

body, button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Asap';
  src: local('Asap-Regular'), url(../fonts/Asap-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Asap';
  src: local('Asap-SemiBold'), url(../fonts/Asap-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Asap';
  src: local('Asap-Bold'), url(../fonts/Asap-Bold.ttf);
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Asap';
  src: local('Asap-Italic'), url(../fonts/Asap-Italic.ttf);
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Asap';
  src: local('Asap-Medium'), url(../fonts/Asap-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Asap';
  src: local('Asap-SemiBoldItalic'), url(../fonts/Asap-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins-Regular'), url(../fonts/Poppins-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins-SemiBold'), url(../fonts/Poppins-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins-Italic'), url(../fonts/Poppins-Italic.ttf);
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins-Medium'), url(../fonts/Poppins-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins-MediumItalic'), url(../fonts/Poppins-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins-SemiBoldItalic'), url(../fonts/Poppins-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}